var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"AddSubStepVue","cancel-variant":"outline-secondary","centered":"","title":"SubStep Form","hide-footer":"","result":_vm.submit}},[_c('b-form',[_c('b-form-group',[_c('validation-observer',{ref:"subStepInfo",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Title En","label-for":"Title En"}},[_c('validation-provider',{attrs:{"name":"title En","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Title En"},model:{value:(_vm.value.title),callback:function ($$v) {_vm.$set(_vm.value, "title", $$v)},expression:"value.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Title Fr","label-for":"Title Fr"}},[_c('validation-provider',{attrs:{"name":"title Fr","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Title Fr"},model:{value:(_vm.value.titleFr),callback:function ($$v) {_vm.$set(_vm.value, "titleFr", $$v)},expression:"value.titleFr"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Content En","label-for":"Content En"}},[_c('validation-provider',{attrs:{"name":"content En","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Content En","rows":"3"},model:{value:(_vm.value.description),callback:function ($$v) {_vm.$set(_vm.value, "description", $$v)},expression:"value.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Content Fr","label-for":"Content Fr"}},[_c('validation-provider',{attrs:{"name":"content Fr","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Content Fr","rows":"3"},model:{value:(_vm.value.descriptionFr),callback:function ($$v) {_vm.$set(_vm.value, "descriptionFr", $$v)},expression:"value.descriptionFr"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Additional links","label-for":"Additional links"}},[_c('validation-provider',{attrs:{"name":"Additional links"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Additional links","rows":"3"},model:{value:(_vm.value.additionalLinks),callback:function ($$v) {_vm.$set(_vm.value, "additionalLinks", $$v)},expression:"value.additionalLinks"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"col-12 d-flex mb-2"},[_c('b-form-group',{attrs:{"label":"SubStep Image","label-for":"SubStep Image"}},[_c('div',{on:{"click":function($event){return _vm.$refs.myFile.click()}}},[_c('input',{ref:"myFile",staticClass:"hidden",attrs:{"type":"file"},on:{"change":_vm.onFileChange}}),(!_vm.value._id)?_c('div',[(_vm.media1)?_c('img',{staticClass:"profile-img",attrs:{"src":_vm.media1 + (_vm.isPreview ? '' : '?' + Math.random()),"alt":"profile image"}}):_c('img',{staticClass:"profile-img",attrs:{"src":require('@/assets/images/pages/upload2.png'),"alt":"","required":""}})]):_c('div',[(!_vm.media1)?_c('img',{staticClass:"profile-img",attrs:{"src":_vm.value.media.path +
                      (_vm.isPreview ? '' : '?' + Math.random()),"alt":"profile image","required":""}}):_c('img',{staticClass:"profile-img",attrs:{"src":_vm.media1 + (_vm.isPreview ? '' : '?' + Math.random()),"alt":"profile image"}}),_c('label',[_vm._v("Change image")])]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.imageErrorMessage))])])])],1)],1)],1),_c('b-button',{staticClass:"float-right",attrs:{"id":"show-btn","disabled":_vm.loading,"variant":"primary"},on:{"click":_vm.submit}},[_vm._v(_vm._s(_vm.loading ? 'Saving...' : 'Save'))]),_c('b-button',{staticClass:"float-right mr-1",attrs:{"id":"cancel-btn","variant":"primary"},on:{"click":_vm.close}},[_vm._v("Cancel")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }