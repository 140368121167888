<template>
  <div class="">
    <StepForm ref="form" action="Update" v-model="formData" @formSubmitted="updateStep" />
  </div>
</template>
  
  <script>
import StepService from "@/services/StepService";
import StepForm from "@/views/components/step/StepForm";

export default {
  name: "UpdateStep",
  components: {
    StepForm
  },
  data() {
    return {
      stepService: new StepService(),
      id: this.$route.fullPath.split('/').pop(),
      formData: {
      },
    }
  },
  methods: {
    updateStep(e) {
      this.stepService.update(this.formData._id, e).then(res => {
      }).catch(err => {
        console.log(err);
       }).finally(() => {
      })
    },
    mappingData(data) {
      return {
        ...data,
       }
    },
    getStep() {
      this.stepService.show(this.id).then(res => {
        this.formData = this.mappingData(res);
      }).catch(err => { }).finally(() => { })
    }
  },
  mounted() {
    this.getStep()
  }
}
</script>
  
  <style scoped>
  </style>
  