<template>
  <!-- modal login-->
  <b-modal
    id="AddSubStepVue"
    cancel-variant="outline-secondary"
    centered
    title="SubStep Form"
    hide-footer
    :result="submit"
  >
    <b-form>
      <b-form-group>
        <validation-observer ref="subStepInfo" tag="form">
          <b-row>
            <b-col md="12">
              <b-form-group label="Title En" label-for="Title En">
                <validation-provider
                  #default="{ errors }"
                  name="title En"
                  rules="required"
                >
                  <b-form-input
                    v-model="value.title"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Title En"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group label="Title Fr" label-for="Title Fr">
                <validation-provider
                  #default="{ errors }"
                  name="title Fr"
                  rules="required"
                >
                  <b-form-input
                    v-model="value.titleFr"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Title Fr"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group label="Content En" label-for="Content En">
                <validation-provider
                  #default="{ errors }"
                  name="content En"
                  rules="required"
                >
                  <b-form-textarea
                    :state="errors.length > 0 ? false : null"
                    placeholder="Content En"
                    rows="3"
                    v-model="value.description"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group label="Content Fr" label-for="Content Fr">
                <validation-provider
                  #default="{ errors }"
                  name="content Fr"
                  rules="required"
                >
                  <b-form-textarea
                    :state="errors.length > 0 ? false : null"
                    placeholder="Content Fr"
                    rows="3"
                    v-model="value.descriptionFr"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group
                label="Additional links"
                label-for="Additional links"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Additional links"
                >
                  <b-form-textarea
                    :state="errors.length > 0 ? false : null"
                    placeholder="Additional links"
                    rows="3"
                    v-model="value.additionalLinks"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <div class="col-12 d-flex mb-2">
              <b-form-group label="SubStep Image" label-for="SubStep Image">
                <div @click="$refs.myFile.click()">
                  <input
                    ref="myFile"
                    type="file"
                    class="hidden"
                    @change="onFileChange"
                  />
                  <div v-if="!value._id">
                    <img
                      v-if="media1"
                      class="profile-img"
                      :src="media1 + (isPreview ? '' : '?' + Math.random())"
                      alt="profile image"
                    />
                    <img
                      v-else
                      class="profile-img"
                      :src="require('@/assets/images/pages/upload2.png')"
                      alt=""
                      required
                    />
                  </div>
                  <div v-else>
                    <img
                      v-if="!media1"
                      class="profile-img"
                      :src="
                        value.media.path +
                        (isPreview ? '' : '?' + Math.random())
                      "
                      alt="profile image"
                      required
                    />
                    <img
                      v-else
                      class="profile-img"
                      :src="media1 + (isPreview ? '' : '?' + Math.random())"
                      alt="profile image"
                    />
                    <label>Change image</label>
                  </div>
                  <small class="text-danger">{{ imageErrorMessage }}</small>
                </div>
              </b-form-group>
            </div>
          </b-row>
        </validation-observer>
        <b-button
          id="show-btn"
          :disabled="loading"
          @click="submit"
          class="float-right"
          variant="primary"
          >{{loading ? 'Saving...' : 'Save'}}</b-button
        >

        <b-button
          id="cancel-btn"
          @click="close"
          class="float-right mr-1"
          variant="primary"
          >Cancel</b-button
        >
      </b-form-group>
    </b-form>
  </b-modal>
</template>
 
<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import "vue-form-wizard/dist/vue-form-wizard.min.css";

import {
  BFormGroup,
  BCard,
  BRow,
  BFormInvalidFeedback,
  BCol,
  BFormTextarea,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BSpinner,
  BFormDatepicker,
  BModal,
  VBModal,
  BForm,
  BFormInput,
  BCardText,
  BButton,
} from "bootstrap-vue";
import { required } from "@validations";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import BCardCode from "@core/components/b-card-code";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import StepService from "@/services/StepService";

export default {
  components: {
    BFormDatepicker,
    Ripple,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    BCardCode,
    BModal,
    VBModal,
    BForm,
    BFormInput,
    BRow,
    BCardText,
    BCol,
    BButton,
    BFormGroup,
    BInputGroup,
    BFormTextarea,
    TabContent,
    BFormInvalidFeedback,
    BSpinner,
    BCard,
    BInputGroupAppend,
    BFormCheckbox,
  },
  props: {
    value: {
      type: Object,
      default: () => {
        return {
          title: "",
          content: "",
          media: null,
          additionalLinks: "",
        };
      },
    },
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      loading: false,
      formData: null,
      required,
      isPreview: false,
      media: null,
      media1: null,
      id: this.$route.fullPath.split("/").pop(),
      stepService: new StepService(),
      imageErrorMessage: undefined,
    };
  },
  mounted() {},
  watch: {},
  methods: {
    submit() {
      this.$refs.subStepInfo.validate().then((success) => {
        if (!this.value._id) {
          if (this.media == null) {
            this.imageErrorMessage = "The image is required";
            return;
          }
        }
        if (success) {
          this.imageErrorMessage = undefined;

          const formData = new FormData();
          formData.append("title", this.value.title);
          formData.append("titleFr", this.value.titleFr);
          formData.append("description", this.value.description);
          formData.append("descriptionFr", this.value.descriptionFr);
          if (this.value.additionalLinks != null)
            formData.append("additionalLinks", this.value.additionalLinks);
          formData.append("stepId", this.id);
          if (this.media != null) formData.append("media", this.media);
          if (this.value._id == null) {
            this.createSubStep(formData);
          } else {
            formData.append("subStepId", this.value._id);
            this.updateSubStep(formData);
          }
        } else {
        }
      });
    },
    close() {
      this.$router.go();
    },
    createSubStep(formData) {
      this.loading = true
      this.stepService
        .createSubStep(formData)
        .then((res) => {
          this.loading =false
          this.$router.go();
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    updateSubStep(formData, id) {
      this.loading = true
      this.stepService
        .updateSubStep(formData, id)
        .then((res) => {
          this.loading =false
          this.$router.go();
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    onFileChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      this.media = e.target.files[0];
      if (!files.length) return;
      this.createImage(files[0]);
    },
    createImage(file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.media1 = e.target.result;
        this.isPreview = true;
      };
      reader.readAsDataURL(file);
    },
  },
};
</script> 
  
  <style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
  