<template>
  <b-card title="Substeps">
    <b-button
      class="ml-auto mb-1"
      variant="outline-primary"
      v-b-modal.AddSubStepVue
      @click="editSubSTep()"
    >
      + {{ "Add Substep" }}
    </b-button>
    <AddSubStepVue :value="modalOptions"> </AddSubStepVue>
    <!-- table -->
    <vue-good-table
      mode="remote"
      :pagination-options="{
        enabled: false,
      }"
      :rows="rows"
      :columns="columns"
      @on-sort-change="onSortChange"
      :isLoading.sync="isLoading"
    >
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field == 'Title'" class="d-flex">
          <label class="ml-1">{{ props.row.title }}</label>
        </span>

        <span v-if="props.column.field == 'TitleFr'" class="d-flex">
          <label class="ml-1">{{ props.row.titleFr }}</label>
        </span>

        <span v-if="props.column.field == 'Description'" class="d-flex">
          <label class="ml-1">{{ props.row.description }}</label>
        </span>

        <span v-if="props.column.field == 'DescriptionFr'" class="d-flex">
          <label class="ml-1">{{ props.row.descriptionFr }}</label>
        </span>

        <span class="text-block d-flex" v-if="props.column.field == 'additionalLinks'">
          <label class="ml-1">{{ props.row.additionalLinks }}</label>
        </span>

        <span v-if="props.column.field == 'Icon'" class="d-flex">
          <b-avatar
            class="m-auto"
            size="32"
            :src="props.row.media.path"
            style="background-color: white"
          />
        </span>

        <!-- Column: Action -->
        <span v-if="props.column.field === 'action'">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item
                v-b-modal.AddSubStepVue
                @click="editSubSTep(props.row)"
              >
                <span
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                >
                  Edit
                </span>
              </b-dropdown-item>

              <b-dropdown-item @click="showRemovePopup(props.row)">
                <span
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                >
                  Delete
                </span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>
      </template>
      <template #emptystate>
        <p class="text-center">
          {{ isOPen ? "Loading..." : "There are no rows" }}
        </p>
      </template>
    </vue-good-table>
    <Modal
      :text="modalOptions.text"
      :ok-button="modalOptions.okButton"
      :cancel-button="modalOptions.cancelButton"
      :show="show"
      @result="modalOptions.modalResult"
      @hidden="show = false"
    />
  </b-card>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdownItem,
  BDropdown,
  BCard,
  BButton,
} from "bootstrap-vue";
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import Prism from "vue-prism-component";
import store from "@/store/index";
import Ripple from "vue-ripple-directive";
import Modal from "@/views/components/shared/Modal/Modal";
import StepService from "@/services/StepService";
import AddSubStepVue from "./AddSubStep.vue";
import { VueGoodTable } from "vue-good-table";
import "@core/scss/vue/libs/vue-good-table.scss";

export default {
  name: "StpeList",
  mixins: [],
  components: {
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    AddSubStepVue,
    BFormSelect,
    Prism,
    BDropdownItem,
    BDropdown,
    BCard,
    BButton,
    Modal,
  },
  directives: {
    Ripple,
  },
  props: {
    value: {
      type: [],
    },
  },
  data() {
    return {
      params: {
        sort_keys: undefined,
        sort_dir: undefined,
        query: undefined,
      },
      columns: [
        { label: "Title En", field: "Title", sortable: false},
        { label: "Title Fr", field: "TitleFr", sortable: false},
        { label: "Content En", field: "Description", sortable: false},
        { label: "Content FR", field: "DescriptionFr", sortable: false},
        { label: "Additional links", field: "additionalLinks", sortable: false},
        { label: "Icon", field: "Icon", sortable: false},
        { label: "Action", field: "action", sortable: false},
      ],
      rows: [],
      isLoading: undefined,
      isOPen: true,
      totalRecords: undefined,
      stepService: new StepService(),
      show: false,
      modalOptions: {
        modalResult : this.modalResultRemove
      },
      id: this.$route.fullPath.split('/').pop(),
    };
  },
  watch: {
    params: {
      handler: function () {
        this.getSubSteps();
      },
      deep: true,
    },
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        this.dir = true;
        return this.dir;
      }
      this.dir = false;
      return this.dir;
    },
  },
  created() {
    this.getSubSteps();
  },
  methods: {
    onSortChange(params) {
      const SortKey =
        typeof params[0].field === "function" ? "client" : params[0].field;
      this.params.sort_keys = [SortKey[0].toLowerCase() + SortKey.slice(1)];
      this.params.sort_dir = [params[0].type];
    },
    modalResultRemove(value) {
      this.show = false;
      if (value) {
        this.deleteSubStep(this.model._id);
      }
    },
    deleteSubStep(id) {
      this.isLoading = true;
      this.stepService
        .deleteSubStep({stepId: this.id, subStepId: id})
        .then((res) => {
          this.$router.go();
        })
        .catch((err) => {})
        .finally(() => {
          this.isLoading = false;
        });
    },
    editSubSTep(value = {}) {
      this.modalOptions = value;
    },
    showRemovePopup(model) {
      this.show = true;
      this.show = true;
      this.model = model;
      this.modalOptions = {
        text: `Are you sure you want to delete this SubStep`,
        okButton: `Delete`,
        cancelButton: "Cancel",
        clickedId: model._id,
        currentAction: "Block",
        modalResult: this.modalResultRemove,
      };
    },
    getSubSteps() {
      this.isLoading = true;
      this.isOPen = false;
      if (this.value)
        this.rows = this.value.map((el) => {
          return {
            ...el,
          };
        });
    },
  },
};
</script>
<style>
.loader-overlay {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: wait;
}

@media (min-width: 576px) {
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
}
@media (max-width: 576px) {
  th,
  td {
    /* font size */
    font-size: 0.857rem;
    font-weight: 400;
    /* text center horizontally */
    vertical-align: middle !important;
    text-align: center;
    line-height: 20px;
  }
}
.text-block {
    white-space: pre;
}
</style>