import CrudService from "@/services/shared/http/CrudService";

export default class StepService extends CrudService {
  constructor(params = {}) {
    super("step", params);
  }
  index(params = {}) {
    return this.api.GET("step/get-steps", params)
  }
  getSubsStep(params = {}) {
    return this.api.GET("step/get-sub-steps", params)
  }
  createSubStep(data, params = {}) {
    return this.api.POST("step/create-sub-step", data, params)
  }
  updateSubStep(data, params = {}) {
    return this.api.POST("step/update-sub-step", data, params)
  }
  deleteSubStep(data, params = {}) {
    return this.api.POST("step/delete-sub-step", data, params)
  }
}
